// Segment snippet
!(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked) {
      window.console && console.error && console.error("Segment snippet included twice.");
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ];
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        var t = document.createElement("script");
        t.type = "text/javascript";
        t.async = !0;
        t.src = "https://sc.mission.dev/analytics.js/v1/" + key + "/analytics.min.js";
        var n = document.getElementsByTagName("script")[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey =process.env.SEGMENT_WRITE_KEY_FE;
      analytics.SNIPPET_VERSION = "4.13.2";
      analytics.load(process.env.SEGMENT_WRITE_KEY_FE);
    }
})();

// This is a mini-wrapper around Segment's Analytics.js that allows us to further simplify
// instrumenting the application under its various environments
class AnalyticsClient {
  constructor() {
    this.environment = window.ENV || "unknown";
    this.shouldRelayToIntegrations = this.environment === "production";
  }

  identify(payload) {
    if (!payload) return null;
    const userId = payload.id;
    window.analytics.identify(userId, traits = payload);
  }

  track(event, payload) {
    if (!payload) return null;
    payload.userId = window.USER_ID === null ? null : window.USER_ID

    window.analytics.track(event, payload);
  }

  page(event, payload) {
    if (!payload) return null;
    if(event == 'undefined') return null;
    payload['userId'] = window.USER_ID === null ? null : window.USER_ID

    window.analytics.page({}, event, payload);
  }
}

// Analytics client
window.analyticsClient = new AnalyticsClient();

// Basic Page Tracking
// document.addEventListener("DOMContentLoaded", function () {
//   window.analyticsClient.page();
// });
